<template>
  <b-card-code title="Ad Group Information">
    <b-overlay 
      :show="show" 
      rounded="lg" 
      no-fade 
    >
        <div :aria-hidden="show ? 'true' : null">
            <b-form 
                ref="form"
                class="mt-1"
                @submit.prevent="createAdGroupForm"
            >
                <b-row>
                    <b-col md="8">
                        <b-row>
                            <!-- Campaign -->
                            <b-col md="6">
                                <b-form-group
                                    label="Campaign"
                                    label-for="campaign"
                                >
                                    <v-select
                                        v-model="campaign"
                                        :reduce="(option) => option.id"
                                        :options="campaigns"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Ad Group Name -->
                            <b-col md="6">
                                <b-form-group
                                    label="Ad Group Name"
                                    label-for="adgroup_name"
                                >
                                    <b-input-group>            
                                    <b-form-input 
                                        placeholder="Ad Group Name" 
                                        name="adgroup_name" 
                                        v-model="adgroup_name"
                                        class="text-success"
                                    />
                                    <b-input-group-append is-text>
                                        <b-form-checkbox
                                            v-model="adgroup_status"
                                            :checked="adgroup_status"
                                            switch
                                            inline
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col md="12"></b-col> 

                            <!-- Inventory Channel -->
                            <b-col md="6">
                                <b-form-group
                                    label="Inventory Channel"
                                    label-for="inventory_channel"
                                >
                                    <v-select
                                        v-model="ichannel"
                                        :reduce="(option) => option.id"
                                        :options="ichannels"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Placement -->
                            <b-col md="6">
                                <b-form-group
                                    label="Ad Placement"
                                    label-for="ad_placement"
                                >
                                    <v-select
                                        v-model="adplacement"
                                        :reduce="(option) => option.id"
                                        :options="adplacements"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Advertiser Rules -->
                            <b-col md="6">
                                <b-form-group
                                    label="Advertiser Rules"
                                    label-for="rule_id"
                                >
                                    <v-select
                                        v-model="rule_id"
                                        :reduce="(option) => option.id"
                                        :options="rules"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Ad Group Name -->
                            <b-col md="6">
                                <b-form-group
                                    label="Ad Group Landing"
                                    label-for="adgroup_click_url"
                                >
                                    <b-form-input 
                                        placeholder="Ad Group Landing url"
                                        v-model="adgroup_click_url"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="12"></b-col> 

                            <b-card-code title="Ad Group Schedule">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-checkbox
                                            id="adgroup_schedule"
                                            :checked="adgroup_schedule"
                                            switch
                                            inline
                                            @change="groupSchedule"
                                        >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CalendarIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="CalendarIcon" />
                                        </span>
                                        </b-form-checkbox>
                                        <span class="text-bold-700 ml-1">
                                            <p class="d-inline"> Ad Group running on campaign schedule </p>
                                            <p class="ml-4" style="padding-left:30px;">Only enable if you want this AdGroup to run on a different schedule than your campaign. </p>
                                        </span>
                                    </b-col> 

                                    <b-col md="12" class="mt-2" v-if="adgroup_schedule == true">
                                        <b-row>
                                            <!-- Activation Date -->
                                            <b-col md="6">
                                                <b-form-group
                                                    label="Activate Date"
                                                    label-for="adgroup_start_date"
                                                >
                                                    <b-input-group class="mb-2">
                                                        <flat-pickr
                                                            v-model="adgroup_start_date"
                                                            class="form-control"
                                                            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s',minDate:new Date(), maxDate: new Date().fp_incr(10), defaultDate: new Date() }"
                                                        />
                                                        <b-input-group-append is-text>
                                                            <feather-icon
                                                            icon="CalendarIcon"
                                                            class="cursor-pointer"
                                                            size="24"
                                                            />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>

                                            <!-- Deactivation Date -->
                                            <b-col md="6">
                                                <b-form-group
                                                    label="Deactivate Date"
                                                    label-for="adgroup_end_date"
                                                >
                                                    <b-input-group class="mb-2">
                                                        <flat-pickr
                                                            v-model="adgroup_end_date"
                                                            class="form-control"
                                                            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:s',minDate:new Date(), maxDate: new Date().fp_incr(60)}"
                                                        />
                                                        <b-input-group-append is-text>
                                                            <feather-icon
                                                            icon="CalendarIcon"
                                                            class="cursor-pointer"
                                                            size="24"
                                                            />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col> 
                                </b-row>
                            </b-card-code>

                            <b-col md="12"></b-col>

                        </b-row>

                    </b-col>

                    <b-col md="4">
                        <!-- right sidebar goal overview -->
                        <h2>Goal Overview</h2>
                        <b-card>
                            <vue-apex-charts
                            type="donut"
                            height="350"
                            :options="donutChart.chartOptions"
                            :series="donutChart.series"
                            />
                        </b-card>
                    </b-col>

                    <b-col md="12">
                        <b-row>

                            <b-card-code title="Day Parting Information">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-checkbox
                                            id="dayparting_auto"
                                            :checked="dayparting_auto"
                                            switch
                                            inline
                                            @change="daypartingAuto"
                                        >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="ZapIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="ZapOffIcon" />
                                        </span>
                                        
                                        </b-form-checkbox>
                                        <span class="text-bold-700 ml-1">
                                            <p class="d-inline"> <b>Automatically</b> running continuously (Recommended) </p>
                                            <p class="ml-4" style="padding-left:30px;">Campaign will run 24/7 within the start and end date. </p>
                                        </span>
                                    </b-col> 

                                    <b-col md="12" class="mt-2" v-if="dayparting_auto == false">
                                        <table class="dayparts">
                                            <thead>
                                                <tr>
                                                  <td class="cell-label timezone-label"></td>
                                                  <td colspan="24"><span class="cell-label timezone-sub-label"></span>
                                                      <b-form-group
                                                          label="Timezone"
                                                          label-for="timezone"
                                                      >
                                                      <v-select
                                                          :reduce="(option) => option.label"
                                                          :options="timezones"
                                                          v-model="timezone"
                                                      />   
                                                      </b-form-group>
                                                  </td>
                                                </tr>
                                                <tr>
                                                    <td class="cell-label presets-label"></td>
                                                    <td colspan="24"><span class="cell-label presetsSubtitle-label"></span>
                                                      <b-form-group
                                                          label="Presets"
                                                          label-for="presets"
                                                      >
                                                      <v-select
                                                          :reduce="(option) => option.id"
                                                          :options="day_presets"
                                                          v-model="day_preset"
                                                          @input="selectedFunc"
                                                      />   
                                                      </b-form-group>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2"></td>
                                                    <td class="cell-label am-label" colspan="12">AM</td>
                                                    <td class="cell-label pm-label" colspan="12">PM</td>
                                                </tr>
                                                <tr class="hour-row">
                                                    <td class="hour" v-for="(h,i) in this.times" :key="i" :value="h.h" @click="setTimeSlot">{{h.h}}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              <tr class="day" v-for="(day,di) in this.days" :key="di">
                                                  <td class="cell-label day-label" :value="day.dayNumber" :day-value="day.dayNumber" @click="activateByDayName"> {{day.dayName}} </td>
                                                  <td class='dayparts-cell' :value="h.h" data='day' :day="day.dayNumber" :class="{active_hour: h.isActive}" v-for='(h,k) in day.times' :key="k" @click="activeDayTime"></td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </b-col>

                                </b-row>
                            </b-card-code>


                            <b-col md="12"></b-col> 

                            <b-card-code title="Frequency Capping">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-checkbox
                                            id="freq_cap_auto"
                                            :checked="freq_cap_auto"
                                            switch
                                            inline
                                            @change="freqCap"
                                        >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="ShieldIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="ShieldOffIcon" />
                                        </span>
                                        
                                        </b-form-checkbox>
                                        <span class="text-bold-700 ml-1">
                                            <p class="d-inline"> <b>Automatically</b> optimizing frequency caps (Recommended) </p>
                                            <p class="ml-4" style="padding-left:30px;">Allow Purple Patch to dynamically optimize how many times your ads appear to the same person. </p>
                                        </span>
                                    </b-col> 

                                    <b-col md="12" v-if="freq_cap_auto == false">
                                        <b-row>
                                            <!-- Activation Date -->
                                            <b-col md="12">
                                                <b-input-group append="Impressions Per User Per Day">
                                                    <b-form-input 
                                                        v-model="freq_cap_info"
                                                        placeholder="10"
                                                        maxlength="2"
                                                    />
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </b-col> 
                                </b-row>
                            </b-card-code>

                            <b-col md="12"> 
                                <b-card-code title="More Options">
                                    <app-collapse>

                                        <app-collapse-item title="Location">
                                            <b-row>
                                                <b-col md="12">
                                                    <b-form-radio-group
                                                        v-model="location_type"
                                                        :options="locations"
                                                    >
                                                    </b-form-radio-group>
                                                </b-col>
                                                <b-col class="mt-2" md="12">
                                                    <b-form-group
                                                        label="Country"
                                                        label-for="country"
                                                    >
                                                        <v-select
                                                            v-model="country"
                                                            :options="countries"
                                                            multiple
                                                            @open="onOpenRadio('location_type')"
                                                        />
                                                    </b-form-group>
                                                </b-col> 
                                            </b-row>
                                            <b-row class="mt-1">
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="region_type"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Region"
                                                            label-for="region"
                                                        >
                                                            <v-select
                                                                v-model="country"
                                                                :options="countries"
                                                                multiple
                                                                @open="onOpenRadio('region_type')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="city_type"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="City"
                                                            label-for="city"
                                                        >
                                                            <v-select
                                                                v-model="country"
                                                                :options="countries"
                                                                multiple
                                                                @open="onOpenRadio('city_type')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="carrier_type"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Carrier"
                                                            label-for="carrier"
                                                        >
                                                            <v-select
                                                                v-model="carrier"
                                                                :options="carriers"
                                                                multiple
                                                                @open="onOpenRadio('carrier_type')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>

                                            </b-row>
                                        </app-collapse-item>

                                        <app-collapse-item title="Device">

                                            <b-row>
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="device_inex_type"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Type of Device"
                                                            label-for="typeofdevice"
                                                        >
                                                            <v-select
                                                                v-model="devicetype"
                                                                :options="devicetypes"
                                                                multiple
                                                                @open="onOpenRadio('device_inex_type')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="device_inex_brand"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Device Brand"
                                                            label-for="devicebrand"
                                                        >
                                                            <v-select
                                                                v-model="devicebrand"
                                                                :options="devicebrands"
                                                                multiple
                                                                @open="onOpenRadio('device_inex_brand')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="4">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="device_inex_model"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Device Model"
                                                            label-for="devicemodel"
                                                        >
                                                            <v-select
                                                                v-model="devicemodel"
                                                                :options="devicemodels"
                                                                multiple
                                                                @open="onOpenRadio('device_inex_model')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>

                                            </b-row>

                                            <b-row class="mt-3">
                                                <b-col md="6">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="os_inex_type"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Device Operating System"
                                                            label-for="os"
                                                        >
                                                            <v-select
                                                                v-model="os"
                                                                :options="oses"
                                                                multiple
                                                                @open="onOpenRadio('os_inex_type')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="osv_inex"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Operating System Version"
                                                            label-for="osv"
                                                        >
                                                            <v-select
                                                                v-model="osv"
                                                                :options="osvs"
                                                                multiple
                                                                @open="onOpenRadio('osv_inex')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>

                                        <app-collapse-item title="Content Verticals">
                                            <b-row>
                                                <b-col md="6">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="iab_categories_operator"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>

                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="IAB Categories"
                                                            label-for="iab_categories"
                                                        >
                                                            <v-select
                                                              :reduce="(option) => option.name"
                                                              v-model="iab_category"
                                                              :options="iab_categories"
                                                              multiple
                                                              @input="updateIABCats"
                                                              @open="onOpenRadio('iab_categories_operator')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-col md="12" class="mb-1">
                                                        <b-form-radio-group
                                                            :options="locations"
                                                            v-model="iab_sub_categories_operator"
                                                        >
                                                        </b-form-radio-group>
                                                    </b-col>
                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="IAB Sub Categories"
                                                            label-for="iab_sub_categories"
                                                        >
                                                            <v-select
                                                              :reduce="(option) => option.name"
                                                              :options="iab_sub_categories"
                                                              v-model="iab_sub_category"
                                                              multiple
                                                              :disabled="iabsubdisabled"
                                                              @open="onOpenRadio('iab_sub_categories_operator')"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>
                                        <app-collapse-item title="Page Keywords">
                                            <b-row>
                                                <b-col md="12" class="mb-1">
                                                    <b-form-radio-group
                                                        :options="locations"
                                                        v-model="page_keywords_operator"
                                                    >
                                                    </b-form-radio-group>
                                                </b-col>
                                                <!-- Ad Group Description -->
                                                <b-col md="10">
                                                    <b-form-group
                                                        label="Ad Group Description"
                                                        label-for="page_keywords"
                                                    >
                                                        <b-form-textarea 
                                                            placeholder="Ad Group Description"
                                                            v-model="page_keywords"
                                                            @keydown="onOpenRadio('page_keywords_operator')"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                            </b-row>
                                        </app-collapse-item>

                                        <app-collapse-item title="Extra">
                                            <b-row>
                                                <!-- Ad Group Description -->
                                                <b-col md="10">
                                                    <b-form-group
                                                        label="Ad Group Description"
                                                        label-for="adgroup_description"
                                                    >
                                                        <b-form-textarea 
                                                            placeholder="Ad Group Description"
                                                            v-model="adgroup_description"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <!-- Ad Group Comment -->
                                                <b-col md="10">
                                                    <b-form-group
                                                        label="Ad Group Comment"
                                                        label-for="adgroup_comment"
                                                    >
                                                        <b-form-textarea 
                                                            placeholder="Ad Group Comment"
                                                            v-model="adgroup_comment"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>

                                    </app-collapse>
                                    
                                </b-card-code>
                            </b-col> 


                            <!-- submit and reset -->
                            <b-col cols="12" class="my-5 text-center">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="mr-1"
                            >
                                Submit
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="reset"
                                variant="outline-secondary"
                                @click="reset"
                            >
                                Reset
                            </b-button>
                            </b-col>
                        </b-row>

                    </b-col>


                </b-row>
            
            </b-form>
        </div>
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormValidFeedback,
    BFormTextarea,
    BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCardCode,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    VueApexCharts,
    BFormRadioGroup
  },
  directives: {
    Ripple
  },
  data() {
    return {
        campaign:'',
        campaigns:[],
        timezone:{ offset: "21200", label: "Asia/Dhaka"},
        timezones:[],
        ichannel:{id:0, label : 'Local'},
        ichannels:[
            {id:0, label : 'Local'}, 
            {id:1, label : 'Global'}, 
            {id:2, label : 'Facebook'}
        ],
        adplacement:[{id:0, label : 'Site'}, {id:1, label : 'App'}],
        adplacements:[
            {id:0, label : 'Site'}, 
            {id:1, label : 'App'}
        ],
        rule_id:null,
        rules:[],
        adgroup_click_url:'',
        adgroup_schedule:false,
        adgroup_start_date: null,
        adgroup_end_date: null,
        dayparting_auto:true,
        freq_cap_auto:true,
        freq_cap_info:'',
        location_type: null,
        locations: [
            { text: 'Include', value: 'include' },
            { text: 'Exclude', value: 'exclude' },
        ],
        country:'',
        countries:[],
        region_type:null,
        region:'',
        regions:[],
        city_type:null,
        city:'',
        cities:[],
        carrier_type:null,
        carrier:'',
        carriers:[],
        device_inex_type:null,
        devicetype:'',
        devicetypes:[],
        device_inex_brand:null,
        devicebrand:'',
        devicebrands:[],
        device_inex_model:null,
        devicemodel:'',
        devicemodels:[],
        os_inex_type:null,
        os:'',
        oses:[],
        osv_inex:null,
        osv:'',
        osvs:[],
        page_keywords_operator:'',
        page_keywords:'',
        inventory_domain:'',
        adgroup_name:'',
        adgroup_status:true,
        inventory_description:'',
        ad_format:[ 
            {label : 'Banner', id:1}, 
            {label : 'Video', id:2}, 
            {label : 'Native', id:3} 
        ],
        inventory_id:0,
        // form submission process handle
        show: false,
        interval: null,
        donutChart: {
            series: [10,20],
            chartOptions: {
            legend: {
                show: true,
                position: 'bottom',
                fontSize: '14px',
                fontFamily: 'Montserrat',
            },
            colors: [ "var(--primary)", "var(--secondary)" ],
            dataLabels: {
                enabled: true,
                formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
                },
            },
            plotOptions: {
                pie: {
                donut: {
                    labels: {
                    show: true,
                    name: {
                        fontSize: '2rem',
                        fontFamily: 'Montserrat',
                    },
                    value: {
                        fontSize: '1rem',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}%`
                        },
                    },
                    total: {
                        show: true,
                        fontSize: '1.5rem',
                        label: 'Operational',
                        formatter() {
                        return '31%'
                        },
                    },
                    },
                },
                },
            },
            labels: ['Impressions', 'Reach'],
            responsive: [
                {
                breakpoint: 992,
                options: {
                    chart: {
                    height: 380,
                    },
                    legend: {
                    position: 'bottom',
                    },
                },
                },
                {
                breakpoint: 576,
                options: {
                    chart: {
                    height: 320,
                    },
                    plotOptions: {
                    pie: {
                        donut: {
                        labels: {
                            show: true,
                            name: {
                            fontSize: '1.5rem',
                            },
                            value: {
                            fontSize: '1rem',
                            },
                            total: {
                            fontSize: '1.5rem',
                            },
                        },
                        },
                    },
                    },
                    legend: {
                    show: true,
                    },
                },
                },
            ],
            },
        },
        day_preset:{id:null,label:'Select a Preset'},
        day_presets:[
        {id:null,label:'Select a Preset'},
        {id:0,label:'None'},
        {id:1,label:'Afternoons'},
        {id:2,label:'Evenings'},
        {id:3,label:'Mornings'},
        {id:4,label:'Weekdays'},
        {id:5,label:'Weekends'},
        {id:6,label:'Weekends including Thursday'}
        ],
        times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}],
        days: [
            { dayName: "Sunday", isActive: false,dayNumber: 0,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, 
            {dayName: "Monday",isActive: false,dayNumber: 1,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, {
            dayName: "Tuesday",isActive: false,dayNumber: 2,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, {
            dayName: "Wednesday",isActive: false,dayNumber: 3,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, {
            dayName: "Thursday",isActive: false,dayNumber: 4,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, {
            dayName: "Friday",isActive: false,dayNumber: 5,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }, {
            dayName: "Saturday",isActive: false,dayNumber: 6,
            times: [{h:'0',isActive:false},{h:'1',isActive:false},{h:'2',isActive:false},{h:'3',isActive:false},{h:'4',isActive:false},{h:'5',isActive:false},{h:'6',isActive:false},{h:'7',isActive:false},{h:'8',isActive:false},{h:'9',isActive:false},{h:'10',isActive:false},{h:'11',isActive:false},{h:'12',isActive:false},{h:'13',isActive:false},{h:'14',isActive:false},{h:'15',isActive:false},{h:'16',isActive:false},{h:'17',isActive:false},{h:'18',isActive:false},{h:'19',isActive:false},{h:'20',isActive:false},{h:'21',isActive:false},{h:'22',isActive:false},{h:'23',isActive:false}]
            }
        ],
        counter: 1,
        hourStart: '',
        hourEnd: '',
        dayStart: '',
        dayEnd: '',
        dayPreStart: '',
        hourPreStart: '',
        dayPreEnd: '',
        hourPreEnd: '',
        elementMove: '',
        dayCheck: {
        isActive: false
        },
        iab_categories_operator:'',
        iab_sub_categories_operator:'',
        iab_category:'',
        iab_categories:[],
        iabsubdisabled:true,
        iab_sub_category:'',
        iab_sub_categories:[],
        adgroup_description:'',
        adgroup_comment:''
    }
  },
  methods: {
    groupSchedule(){
        this.adgroup_schedule = !this.adgroup_schedule;
    },
    daypartingAuto(){
        this.dayparting_auto = !this.dayparting_auto;
    },
    freqCap(){
        this.freq_cap_auto = !this.freq_cap_auto;
    },
    reset(){
        console.log('testing reset');
    },
    createAdGroupForm(){
        this.show = true;
        const accessToken = useJwt.getToken('accessToken');
        var dayparting_info = [];
        this.days.forEach(day => {
            var time_data = '';
            day.times.forEach(time => {
                if(time.isActive){
                    time_data += day.dayNumber +':'+ time.h +',';
                }
            });
            if(time_data){
                dayparting_info.push(time_data.slice(0,-1));
            }
        });

         let form_new_data = {
            campaign_id: this.campaign,
            adgroup_name: this.adgroup_name,
            placement : this.adplacements.map(i=>i.id).join(),
            adgroup_status: this.adgroup_status ==true?1:0,
            adgroup_description:this.adgroup_description,
            adgroup_comment:this.adgroup_comment,
            rule_id:this.rule_id,
            adgroup_click_url:this.adgroup_click_url,
        };

        form_new_data.channel = (this.ichannel.id === undefined ) ? this.ichannel.join() : this.ichannel.id;
        if(this.adgroup_schedule){
            form_new_data.adgroup_start_date = this.adgroup_start_date;
            form_new_data.adgroup_end_date = this.adgroup_end_date;
            form_new_data.schedule_manual = 1;
        }

        if(!this.dayparting_auto){
            form_new_data.timezone = (this.timezone.label === undefined ) ? this.timezone : this.timezone.label;
            form_new_data.dayparting_data = dayparting_info.join();
            form_new_data.dayparting_auto = 0;
        }

        if(!this.freq_cap_auto){
            form_new_data.freq_cap_auto = 0;
            form_new_data.freq_cap_info = this.freq_cap_info;
        }

        if(this.iab_category){
            form_new_data.iab_categories_operator = this.iab_categories_operator=='include'? 1: 0;
            form_new_data.iab_categories = this.iab_category.join(',');
        }

        if(this.iab_sub_category){
            form_new_data.iab_sub_categories_operator = this.iab_sub_categories_operator=='include'? 1: 0;
            form_new_data.iab_sub_categories = this.iab_sub_category.join(',');
        }

        if( this.page_keywords.length > 0 ){
            form_new_data.page_keywords = this.page_keywords;
            form_new_data.page_keywords_operator = this.page_keywords_operator == 'include' ? 1 : 0;
        }
        
        console.log(form_new_data);

        this.$http.post('https://api.purplepatch.online/advertiser/ad-group/create/', form_new_data, { 
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            } 
        })
        .then(response => {
            this.show = false;
            console.log(response);
            if( response.status == '201' ){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: response.data.msg,
                        icon: 'AwardIcon',
                        variant: 'success',
                        text: response.data.msg
                    },
                });
                return router.push({ name: 'advertiser-banner-create' });
            } else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: response.data.msg,
                        icon: 'BellOffIcon',
                        variant: 'error',
                        text: response.data.msg
                    },
                });
                return router.push({ name: 'advertiser-campaigns' });
            }
        })
        .catch(error => {
            console.log(error);
                this.show = false;
            // if( response.status != '201' ){
                this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: error.msg,
                    icon: 'CoffeeIcon',
                    variant: 'error',
                    text: error.msg
                },
                });
                // return router.push({ name: 'advertiser-ad-groups' });
            // }
            }
        );
    },
    updateIABCats(data){
      console.log(data);
        // console.log(this.slot_iab_sub_categories);
        const accessToken = useJwt.getToken('accessToken')
        this.$http.post('https://api.purplepatch.online/getiabsubcategories', {'iab_categories':data}, {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
      .then(res => {
            if(res.data){
                this.iabsubdisabled=false;
                this.iab_sub_categories=res.data;
            }
        })
    },
    setTimeSlot(e){
      var element = e.currentTarget;
      var value = parseInt(element.getAttribute('value'));
      this.times[value].isActive = !this.times[value].isActive
      if (this.times[value].isActive === true) {
        for (var i = 0; i < 7; i++) {
          this.days[i].times[value].isActive = true
        }
      } else if (this.times[value].isActive === false) {
        for ( var i = 0; i < 7; i++) {
          this.days[i].times[value].isActive = false
        }
      }
    },
    activateByDayName(e) {
      var element = e.currentTarget;
      var value = parseInt(element.getAttribute('value'));
      this.days[value].isActive = !this.days[value].isActive
      if (this.days[value].isActive === true) {
        for (var i = 0; i < this.times.length; i++) {
          this.days[value].times[i].isActive = true
        }
      } else if (this.days[value].isActive === false) {
        for (var i = 0; i < this.times.length; i++) {
          this.days[value].times[i].isActive = false
        }
      }
    },   
    activeDayTime(e){
        let element = e.currentTarget;
        let value = parseInt(element.getAttribute('value'));
        let day = parseInt(element.getAttribute('day'));
        this.days[day].times[value].isActive=!this.days[day].times[value].isActive;
    },
    clearAll() {
      for (var i = 0; i < this.days.length; i++) {
        for (var j = 0; j < this.days[0].times.length; j++) {
          this.days[i].isActive = false;
          this.days[i].times[j].isActive = false;
        }
      }
    },
    selectedFunc() {
        this.clearAll();
        switch(this.day_preset){
            case 0:
                for (var i = 0; i < this.days.length; i++) {
                    for (var j = 0; j < this.days[0].times.length; j++) {
                        this.days[i].isActive = false
                        this.days[i].times[j].isActive = false
                    }
                }
                break;
            case 1:
                for (var i = 0; i < this.days.length; i++) {
                    for (var j = 12; j < 18; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            case 2:
                for (var i = 0; i < this.days.length; i++) {
                    for (var j = 18; j < 24; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            case 3: 
                for (var i = 0; i < this.days.length; i++) {
                    for (var j = 6; j < 12; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            case 4:
                for (var i = 0; i < 5; i++) {
                    for (var j = 0; j < this.days[0].times.length; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            case 5:
                for (var i = 5; i < 7; i++) {
                    for (var j = 0; j < this.days[0].times.length; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            case 6:
                for (var i = 4; i < 7; i++) {
                    for (var j = 0; j < this.days[0].times.length; j++) {
                        this.days[i].isActive = true
                        this.days[i].times[j].isActive = true
                    }
                }
                break;
            default:
                console.log('default');
                break;
        }      
    },
    onOpenRadio(e){
        if( !this[e] ){
            this[e]='include';
        }
    },
    validDomain(domain){
        return (/^(?:(http:\/\/|https:\/\/))?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9\/]{2,})+$/.test(domain)) ? true : false;
    },
  },
  created() {
        const accessToken = useJwt.getToken('accessToken')
        this.$http.get('https://api.purplepatch.online/advertiser/ad-groups/campaign-list', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(response => {
            this.campaigns = response.data;
        });

        // Rule List
        this.$http.get('https://api.purplepatch.online/rules/list', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(response => {
            console.log(response.data);
            this.rules = response.data;
        });

        // Timezone
        this.$http.get('https://api.purplepatch.online/advertiser/ad-groups/timezone', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(response => {
            // console.log(response.data);
            this.timezones = response.data;
        });

        // Country Api
        this.$http.post('https://api.purplepatch.online/advertiser/countries', {
          get_countries:true
        })
        .then(response => {
            console.log(response);
            // this.countries = response.data;
        });

        // IAB Category API
        this.$http.get('https://api.purplepatch.online/getiabcategories', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(response => {
            this.iab_categories = response.data;
        });
  },
}
</script>

<style>
  .dayparts{
    max-width: 100%;
  }
  .dayparts td{
    padding:8px;
  }
  .dayparts-cell {
    transition: .3s;
    background-color:var(--gray);
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border: 1px solid var(--light);
    cursor: pointer;
  }
.day-label {
  text-align: right;
  padding: 5px;
  transition: .3s;
}
.active_hour{background-color: var(--primary) !important;}
.hour {
  transition: .3s;  
}
.day-label, .hour{cursor: pointer;}

.day-label:hover,
.hour:hover {
  color: var(--primary);
}

.dayparts-cell:hover {
  background-color: var(--primary);
}

.active_hour:hover {
  background-color: var(--danger) !important;
}
</style>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

