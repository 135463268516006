<template>
  <b-card>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $attrs.title }}</b-card-title>
        <b-card-sub-title v-if="$attrs['sub-title']">
          {{ $attrs['sub-title'] }}
        </b-card-sub-title>
      </div>

    </div>

    <b-card-body>
      <slot />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardBody
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  },
  data() {
    return {
      parentID: '',
    }
  },
  computed: {
    cardAttrs() {
      const cardAttrs = JSON.parse(JSON.stringify(this.$attrs))
      delete cardAttrs.title
      delete cardAttrs['sub-title']
      return cardAttrs
    },
  },
  created() {
    this.parentID = String(Math.floor(Math.random() * 10) + 1)
  },
}
</script>

<style lang="scss" scoped>
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
</style>
